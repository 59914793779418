(function () {
    var userAgent = window.navigator.userAgent;
    var isIE = /MSIE |Trident/.test(userAgent);

    if (isIE) {

        var appElements = document.getElementsByTagName('us-app');
        if (appElements.length) {
            appElements[0].style.display = 'none';
        }

        var loadingElements = document.getElementsByTagName('div');
        if (loadingElements.length) {
            loadingElements[0].style.display = 'none';
        }

        var body = document.getElementsByTagName('body')[0];
        var guardMessageContainer = document.createElement('div');
        guardMessageContainer.style.cssText = 'text-align: center; margin: auto; padding: 200px 100px;';
        body.appendChild(guardMessageContainer);

        var supportedLanguages = [
            ['en', 'English'],
            ['it', 'Italiano'],
            ['pt-br', 'Português'],
            ['ru', 'Русский']
        ];
        var chromeLink =
            '<a style="color: #357bb7;" href="https://www.google.com/chrome/">Google Chrome</a>';
        var firefoxLink =
            '<a style="color: #357bb7;" href="https://www.mozilla.org/firefox/">Firefox</a>';
        var edgeLink =
            '<a style="color: #357bb7;" href="https://www.microsoft.com/edge/">Microsoft Edge</a>';
        var messages = {
            'en': 'You are attempting to view this website in an <strong>unsupported</strong> browser.<br> Please download ' +
                firefoxLink + ', ' + chromeLink + ' or ' + edgeLink + ' to view this website.',
            'it': 'Stai visualizzando questo sito web con un browser non <strong>supportato</strong>.<br> Si prega di scaricare ' +
                firefoxLink + ', ' + chromeLink + ' o ' + edgeLink + ' per visualizzare questo sito Web.',
            'pt-br': 'Esta tentando visualizar este site em um navegador <strong>não suportado</strong>.<br> Por favor baixe o ' +
                firefoxLink + ', ' + chromeLink + ' ou ' + edgeLink + ' para ver esta pagina',
            'ru': 'Вы пытаетесь открыть этот сайт в <strong>неподдерживаемом</strong> браузере.<br> Пожалуйста скачайте ' +
                firefoxLink + ', ' + chromeLink + ' или ' + edgeLink + ' чтобы открыть этот сайт.'
        };
        var languageLabels = {
            'en': 'Language ',
            'it': 'Linguaggio ',
            'pt-br': 'Língua ',
            'ru': 'Язык '
        };
        var messageElement = document.createElement('p');
        messageElement.innerHTML = messages['en'];
        messageElement.style.cssText =
            'line-height: 2rem; font-size: 1rem; color: #484848;';

        var labelElement = document.createElement('label');
        labelElement.innerHTML = languageLabels['en'];

        var languageSelectElement = document.createElement('select');
        for (var i = 0; i < supportedLanguages.length; i++) {
            var optionElement = document.createElement('option');
            if (i === 0) {
                optionElement.selected = true;
            }
            optionElement.value = supportedLanguages[i][0];
            optionElement.innerHTML = supportedLanguages[i][1];
            languageSelectElement.appendChild(optionElement);
        }

        if (languageSelectElement.addEventListener) {
            languageSelectElement.addEventListener('change', onLanguageSelect);
        } else {
            languageSelectElement.attachEvent('onchange', onLanguageSelect);
        }

        guardMessageContainer.appendChild(messageElement);
        guardMessageContainer.appendChild(labelElement);
        guardMessageContainer.appendChild(languageSelectElement);
    }

    function onLanguageSelect() {
        var selectedLanguage = languageSelectElement.options[languageSelectElement
            .selectedIndex].value;
        messageElement.innerHTML = messages[selectedLanguage];
        labelElement.innerHTML = languageLabels[selectedLanguage];
    }
}())